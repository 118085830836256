
import Vue from 'vue'
import { createPopup } from '@typeform/embed'

export default Vue.extend({
  name: 'CommonSalesDialog',
  props: {
    dialogId: String,
    dialogType: String,
  },
  data() {
    return {
      toggleTypeformDialog: () => {},
    }
  },
  methods: {
    talkToSalesClicked(dialogType: string, dialogId: string): void {
      dialogType = dialogType || this.dialogType
      dialogId = dialogId || this.dialogId

      if (dialogType && dialogId) {
        switch (dialogType) {
          case 'Calendly':
            // @ts-ignore
            Calendly.initPopupWidget({
              url: `https://calendly.com/${dialogId}`,
              parentElement: document?.getElementsByTagName('body')?.[0],
            })
            break
          case 'Typeform':
            this.toggleTypeformDialog()
            break
        }

        this.$emit('talkToSalesClicked')
      }
    },
  },
  computed: {
    isCalendlyDialog(): Boolean {
      return (this.dialogType as string) === 'Calendly'
    },
  },
  mounted() {
    if (this.dialogType === 'Typeform') {
      const { toggle } = createPopup(this.dialogId)
      // @ts-ignore
      this.toggleTypeformDialog = toggle
    }
  },
})
